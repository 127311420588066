import React from 'react'

import PageHead from 'components/head/PageHead'
import RampLayout from 'templates/ramp'

function BuyCryptoPage() {
  return <RampLayout page="buy-crypto" />
}

export default BuyCryptoPage

// <head> component:
export function Head() {
  return <PageHead page="buy-crypto" />
}
